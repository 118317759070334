<template>
    <div ref="topRoot" class="gantt relative select-none">
        <div ref="tooltip" class="absolute p-1 bottom-0 left-0"></div>
        <!-- columns headers -->
        <div class="flex headers-to-repeat" ref="headers">
            <div class="border-r flex items-end" ref="tableHeaders">
                <div class="border-t border-b flex font-bold">
                    <div :style="'width: calc(' + nameColumnWidth + ')'" class="border-l">
                        <div v-if="options.sortKey === 'name'" class="flex justify-center gap-1">
                            <header-cell :title="$t('commons.name')" />
                            <icon-menu-up width="16" height="16" />
                        </div>
                        <button
                            v-else
                            class="hover:underline flex justify-center w-full"
                            @click="$emit('sortBy', 'name')"
                        >
                            <header-cell :title="$t('commons.name')" />
                        </button>
                    </div>
                    <div class="border-r" :style="{ width: prrefColumnWidth }" v-if="hasData"></div>
                    <div
                        class="border-r flex justify-center w-full"
                        :style="{ width: bundleColumnWidth }"
                        v-if="options.showBundleColumn && hasData"
                    >
                        Lot
                    </div>
                    <div class="border-r" :style="{ width: datesColumnWidth }" v-if="hasData">
                        <div v-if="options.sortKey === 'date'" class="flex justify-center gap-1">
                            <header-cell :title="$t('project.follow.planning.startDate')" />
                            <icon-menu-up width="16" height="16" />
                        </div>
                        <button
                            v-else
                            class="hover:underline flex justify-center w-full"
                            @click="$emit('sortBy', 'date')"
                        >
                            <header-cell :title="$t('project.follow.planning.startDate')" />
                        </button>
                    </div>
                    <div class="border-r" :style="{ width: datesColumnWidth }" v-if="hasData">
                        <header-cell :title="$t('project.follow.planning.endDate')" />
                    </div>
                    <div
                        class="border-r"
                        :style="{ width: progressColumnWidth }"
                        v-if="options.showProgressColumn && hasData"
                    >
                        <header-cell icon="icon-percent" :title="$t('project.follow.planning.progress')" />
                    </div>
                    <div
                        class="border-r"
                        :style="{ width: quantityColumnWidth }"
                        v-if="options.showQuantityColumn && hasData"
                    >
                        <header-cell icon="icon-weight" :title="$t('project.follow.planning.quantity')" />
                    </div>
                    <div
                        class="border-r"
                        :style="{ width: durationColumnWidth }"
                        v-if="options.showDurationColumn && hasData"
                    >
                        <header-cell
                            icon="icon-arrow-expand-horizontal"
                            :title="$t('project.follow.planning.duration')"
                        />
                    </div>
                    <div :style="{ width: lateColumnWidth }" v-if="options.showLateColumn && hasData">
                        <header-cell icon="icon-clock-alert-outline" :title="$t('project.follow.planning.late')" />
                    </div>
                </div>
            </div>
            <div
                ref="grid"
                class="grid flex-grow text-gray-700 bg-white"
                :style="{
                    'grid-template-columns': 'repeat(' + numberOfDays + ', minmax(0, 1fr))',
                }"
            >
                <div
                    v-for="step in superSteps"
                    :key="'hparent' + step.id"
                    class="p-2 truncate"
                    :class="step.classes"
                    :style="{
                        'grid-column': 'span ' + step.colspan + ' / span ' + step.colspan,
                    }"
                >
                    <span>{{ step.label }}</span>
                </div>
                <div
                    v-for="(step, index) in steps"
                    :key="'hstep' + step.id"
                    :style="{
                        'grid-column': 'span ' + step.colspan + ' / span ' + step.colspan,
                    }"
                    class="truncate border-b border-t text-center"
                    :class="{ ...step.classes, firstStep: index === 0 }"
                >
                    <span>{{ step.label }}</span>
                </div>
            </div>
        </div>
        <div class="flex chart" ref="chart" :style="{ 'max-height': maxGanttHeight + 'px' }">
            <div class="flex flex-col w-full relative" ref="list">
                <VerticalHighlightLayer :tableWidth="tableWidth" :dayWidth="dayWidth" :steps="steps" />
                <div
                    class="relative overflow-y-scroll overflow-x-hidden"
                    :style="{ 'max-height': maxGanttHeight + 'px' }"
                >
                    <div v-for="item of tasks" :key="item.id">
                        <div
                            class="line flex border-b border-r"
                            @click="onRowClick(item)"
                            :class="selectedItem && selectedItem.id === item.id ? 'bg-gray-300' : 'hover:bg-gray-100'"
                            style="page-break-inside: avoid"
                            @contextmenu="onContextMenu($event, item)"
                        >
                            <a :id="'uuid_' + item.id"></a>
                            <div class="flex border-r">
                                <div
                                    class="flex items-center border-l overflow-x-hidden overflow-y-auto"
                                    :style="{
                                        'padding-left': item.level ? 16 * item.level + 'px' : '0',
                                        width: 'calc(' + nameColumnWidth + ')',
                                    }"
                                    :class="{
                                        [item.class]: true,
                                    }"
                                >
                                    <template v-if="options.showCollapseButtons">
                                        <button
                                            @click.stop="toggleCollapse(item)"
                                            v-if="!item.collapsed && item.children?.length > 0"
                                            aria-label="collapse this group"
                                        >
                                            <icon-minus-box-outline
                                                class="cursor-pointer"
                                                width="16px"
                                                height="16px"
                                            ></icon-minus-box-outline>
                                        </button>
                                        <button
                                            @click.stop="toggleCollapse(item)"
                                            v-if="item.collapsed && item.children?.length > 0"
                                            aria-label="expand this group"
                                        >
                                            <icon-plus-box-outline
                                                class="cursor-pointer"
                                                width="16px"
                                                height="16px"
                                            ></icon-plus-box-outline>
                                        </button>
                                    </template>
                                    <span
                                        class="mx-1 line-clamp-2"
                                        :class="{ 'text-red-700': item.isError }"
                                        :title="item.name"
                                    >
                                        <app-text-link
                                            v-model="item.name"
                                            v-if="item.editable"
                                            :edited="item.edited"
                                            @focus="$emit('itemNameFocus', item)"
                                            @input="$emit('updatedName', item)"
                                            @blur="$emit('itemNameBlur', item)"
                                        />
                                        <span v-else>{{ item.name }}</span>
                                    </span>
                                    <span
                                        v-if="item.isError"
                                        :title="$t('project.follow.planning.error.' + item.errorMessage)"
                                    >
                                        <icon-all-inclusive
                                            width="16"
                                            height="16"
                                            :class="{ 'text-red-700': item.isError }"
                                            v-if="item.errorMessage === 'predecessor-loop'"
                                        />
                                        <icon-link-variant-off
                                            width="16"
                                            height="16"
                                            :class="{ 'text-red-700': item.isError }"
                                            v-else-if="item.errorMessage === 'missing-predecessor-error'"
                                        />
                                        <icon-help-rhombus
                                            width="16"
                                            height="16"
                                            :class="{ 'text-red-700': item.isError }"
                                            v-else
                                        />
                                    </span>
                                </div>
                                <div
                                    class="flex flex-col justify-center border-r text-right px-1"
                                    :style="{ width: prrefColumnWidth }"
                                    v-if="hasData"
                                >
                                    <p v-if="options.showReferenceData">
                                        <span :title="$t('project.planning.RefLegend')" style="color: #f74c14">
                                            Ref
                                        </span>
                                    </p>
                                    <p v-if="options.showPlannedData">
                                        <span :title="$t('project.planning.PLegend')" class="text-blue-700">P</span>
                                    </p>
                                    <p v-if="options.showRealData">
                                        <span :title="$t('project.planning.RLegend')">R</span>
                                    </p>
                                </div>
                                <div
                                    class="flex flex-col justify-center border-r text-right px-1"
                                    :style="{ width: bundleColumnWidth }"
                                    v-if="options.showBundleColumn && hasData"
                                >
                                    <span class="hover:underline" @click="$emit('bundleFocus', $event)">
                                        {{ item.bundle?.reference || (item.children?.length ? '' : '?') }}
                                    </span>
                                </div>
                                <div
                                    class="flex items-center border-r no-whitespace-no-wrap"
                                    :style="{
                                        'background-color': item.realStartDate ? '#386da51c' : '',
                                        width: datesColumnWidth,
                                    }"
                                    v-if="hasData"
                                >
                                    <start-dates-cell2
                                        :task="item"
                                        @updateStartDate="$emit('updateStartDate', $event)"
                                        @updateRealStartDate="$emit('updateRealStartDate', $event)"
                                        :showRealData="options.showRealData"
                                        :showReferenceData="options.showReferenceData"
                                        :showPlannedData="options.showPlannedData"
                                        :disabled="disabled"
                                    ></start-dates-cell2>
                                </div>
                                <div
                                    class="flex items-center border-r no-whitespace-no-wrap"
                                    :style="{
                                        'background-color': item.realEndDate ? '#386da51c' : '',
                                        width: datesColumnWidth,
                                    }"
                                    v-if="hasData"
                                >
                                    <end-dates-cell2
                                        :task="item"
                                        @updateEndDate="$emit('updateEndDate', $event)"
                                        @updateRealEndDate="$emit('updateRealEndDate', { ...item, ...$event })"
                                        :showRealData="options.showRealData"
                                        :showReferenceData="options.showReferenceData"
                                        :showPlannedData="options.showPlannedData"
                                        :disabled="disabled"
                                    ></end-dates-cell2>
                                </div>
                                <div
                                    class="flex flex-col items-end px-1 border-r no-whitespace-no-wrap"
                                    :style="{ width: progressColumnWidth }"
                                    v-if="options.showProgressColumn && hasData"
                                >
                                    <template v-if="options.showReferenceData">
                                        <span v-if="item.referenceProgress || item.referenceProgress === 0">
                                            {{ Math.round(item.referenceProgress) + '%' }}
                                        </span>
                                        <span v-else>&nbsp;</span>
                                    </template>
                                    <template v-if="options.showPlannedData">
                                        <span v-if="item.expectedProgress || item.expectedProgress === 0">
                                            {{ Math.round(item.expectedProgress) + '%' }}
                                        </span>
                                        <span v-else>&nbsp;</span>
                                    </template>
                                    <div class="flex flex-col w-full" v-if="options.showRealData">
                                        <app-number-link
                                            @enter="focusToNextProgress(item)"
                                            :showLabel="false"
                                            :ref="'progress_' + item.id"
                                            v-model="item.progress"
                                            :disabled="disabled || item.children?.length > 0 || !!item.realEndDate"
                                            :label="$t('project.follow.planning.progress')"
                                            @input="$emit('updateProgress', { ...item, progress: $event })"
                                            class="justify-end"
                                        >
                                            <span
                                                :class="{
                                                    underline:
                                                        !disabled && !(item.children?.length > 0 || !!item.realEndDate),
                                                }"
                                            >
                                                {{ Math.floor(item.progress || 0) + '%' }}
                                            </span>
                                        </app-number-link>
                                    </div>
                                </div>
                                <div
                                    class="flex items-center border-r no-whitespace-no-wrap"
                                    :style="{ width: quantityColumnWidth }"
                                    v-if="options.showQuantityColumn && hasData"
                                >
                                    <span
                                        v-if="item.type === 'task'"
                                        class="mx-2 line-clamp-2"
                                        :title="$t('project.follow.planning.quantity')"
                                    >
                                        {{
                                            item.quantity
                                                ? `${item.quantity} ${item.service.unit ? ' ' + item.service.unit : ''}`
                                                : ''
                                        }}
                                    </span>
                                </div>
                                <div
                                    class="flex flex-col justify-end items-center border-r no-whitespace-no-wrap px-1"
                                    :style="{ width: durationColumnWidth }"
                                    v-if="options.showDurationColumn && hasData"
                                >
                                    <div class="text-right w-full" v-if="options.showReferenceData">
                                        <span>
                                            {{ item.referenceDuration }}
                                        </span>
                                    </div>
                                    <div class="flex flex-col w-full" v-if="options.showPlannedData">
                                        <span
                                            :title="
                                                item.hasEEPredecessor && item.hasXSPredecessor
                                                    ? $t('project.follow.planning.durationDefinedByPredecessors')
                                                    : $t('project.follow.planning.plannedDuration')
                                            "
                                        >
                                            <app-number-link
                                                @enter="focusToNextDuration(item)"
                                                :ref="'duration_' + item.id"
                                                :show-label="false"
                                                v-model="item.duration"
                                                :disabled="
                                                    item.children?.length > 0 ||
                                                    !!item.realEndDate ||
                                                    (item.hasEEPredecessor && item.hasXSPredecessor)
                                                "
                                                :label="
                                                    item.hasEEPredecessor && item.hasXSPredecessor
                                                        ? $t('project.follow.planning.durationDefinedByPredecessors')
                                                        : $t('project.follow.planning.plannedDuration')
                                                "
                                                @input="$emit('updateDuration', { id: item.id, duration: $event })"
                                                class="justify-end"
                                            >
                                                <span
                                                    :class="{
                                                        underline:
                                                            !disabled &&
                                                            !(
                                                                item.children?.length > 0 ||
                                                                !!item.realEndDate ||
                                                                (item.hasEEPredecessor && item.hasXSPredecessor)
                                                            ),
                                                    }"
                                                >
                                                    {{
                                                        (item.hasEEPredecessor && item.hasXSPredecessor ? '↦' : '') +
                                                        (item.duration || (item.children?.length > 0 ? '' : '...'))
                                                    }}
                                                </span>
                                            </app-number-link>
                                        </span>
                                    </div>
                                    <div class="text-right w-full" v-if="options.showRealData">
                                        <span>
                                            {{ item.realDuration || item.estimatedRealDuration }}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    class="flex flex-col justify-end items-center"
                                    :style="{ width: lateColumnWidth }"
                                    v-if="options.showLateColumn && hasData"
                                >
                                    <div
                                        class="flex items-center justify-end no-whitespace-no-wrap"
                                        :class="{
                                            'text-green-600 font-bold': item.referenceLate < 0,
                                            'text-red-600 font-bold': item.referenceLate > 0,
                                        }"
                                        v-if="options.showLateColumn && options.showReferenceData"
                                    >
                                        <span
                                            class="mx-2"
                                            :title="$t('project.follow.planning.absoluteLateTips')"
                                            v-if="item.referenceLate > 0 || item.referenceLate < 0"
                                        >
                                            {{
                                                item.referenceLate > 0
                                                    ? '+' + item.referenceLate
                                                    : item.referenceLate < 0
                                                    ? item.referenceLate
                                                    : ''
                                            }}
                                        </span>
                                        <span v-else>&nbsp;</span>
                                    </div>
                                    <div
                                        class="flex items-center justify-end no-whitespace-no-wrap"
                                        :class="{
                                            'text-green-600 font-bold': item.late < 0,
                                            'text-red-600 font-bold': item.late > 0,
                                        }"
                                        v-if="options.showLateColumn && options.showPlannedData"
                                    >
                                        <span class="mx-2" :title="$t('project.follow.planning.relativeLate')">
                                            {{ item.late > 0 ? '+' + item.late : item.late }}
                                        </span>
                                    </div>
                                    <div
                                        class="flex items-center justify-end no-whitespace-no-wrap"
                                        :class="{
                                            'text-green-600 font-bold': item.criticalLate < 0,
                                            'text-red-600 font-bold': item.criticalLate > 0,
                                        }"
                                        v-if="options.showLateColumn && options.showRealData"
                                    >
                                        <template v-if="false">
                                            <!-- hide critical delay until milestone creation -->
                                            <span
                                                class="mx-2"
                                                :title="$t('project.follow.planning.criticalLateTips')"
                                                v-if="item.criticalLate > 0 || item.criticalLate < 0"
                                            >
                                                {{
                                                    item.criticalLate > 0
                                                        ? '+' + item.criticalLate
                                                        : item.criticalLate < 0
                                                        ? item.criticalLate
                                                        : ''
                                                }}
                                            </span>
                                            <span v-else>&nbsp;</span>
                                        </template>
                                        <span v-else>&nbsp;</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow">
                                <gantt-line2
                                    :value="minDate"
                                    @input="$emit('navigateToDate', $event)"
                                    :task="item"
                                    :dayWidth="dayWidth"
                                    :width="numberOfDays * dayWidth"
                                    :height="lineHeight"
                                    :show-progress-line="options.showProgressLine"
                                    :showReference="options.showReference"
                                    :showReal="options.showReal"
                                    :progressReportedTo="options.progressReportedTo"
                                    :showPlanned="options.showPlanned"
                                    :refDate="options.refDate"
                                    :viewBox.camel="svgOffset + ' 0 ' + numberOfDays * dayWidth + ' ' + lineHeight"
                                    @click="onRowClick(item)"
                                    @showTooltip="showTooltip"
                                    @hideTooltip="hideTooltip"
                                    :offDays="agenda"
                                ></gantt-line2>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="padding-bottom: 40px"></div>
            </div>
        </div>
    </div>
</template>

<script>
import AppButton from '../appButton/AppButton';
import VerticalHighlightLayer from './VerticalHighlightLayer';
import HeaderCell from './HeaderCell';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import GanttLine2 from './GanttLine2';
import { getNumberOfDays, getSteps, getSuperSteps } from './stepService';
import IconLinkVariantOff from '@/icons/IconLinkVariantOff';
import IconHelpRhombus from '@/icons/IconHelpRhombus';
import StartDatesCell2 from '@/components/gantt/StartDatesCell2';
import EndDatesCell2 from '@/components/gantt/EndDatesCell2';
import AppNumberLink from '@/components/appNumberLink/AppNumberLink';
import IconAllInclusive from '@/icons/IconAllInclusive';
import AppLabel from '@/components/appLabel/AppLabel';
import { getTaskLeft, convertEmToPixels } from '@/components/gantt/ganttService';
import startOfDay from 'date-fns/startOfDay';
import { humanizeDate } from '@/filters/dateFilter';
import { queryProject } from '@/features/projects/projects.service';
import AppTextLink from '@/components/appTextLink/AppTextLink.vue';
import AppBundlePicker from '@/components/appBundlePicker.vue';

export default {
    name: 'gantt',
    components: {
        AppBundlePicker,
        AppTextLink,
        AppLabel,
        IconAllInclusive,
        AppNumberLink,
        EndDatesCell2,
        StartDatesCell2,
        IconHelpRhombus,
        IconLinkVariantOff,
        GanttLine2,
        HeaderCell,
        VerticalHighlightLayer,
        AppButton,
    },
    props: ['tasks', 'options', 'selectedItem', 'agenda', 'minDate', 'maxDate'],
    data: function () {
        return {
            disabled: true,
            timer: null,
            dayWidth: 0,
            maxGanttHeight: 0,
            lineHeight: 45,
            nameColumnWidth: '12.5rem + 46px',
            prrefColumnWidth: '2rem',
            bundleColumnWidth: '3rem',
            datesColumnWidth: '4.5rem',
            durationColumnWidth: '3rem',
            quantityColumnWidth: '3rem',
            progressColumnWidth: '2.5rem',
            lateColumnWidth: '3rem',
        };
    },
    beforeMount() {
        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        scrollBy(yDiff) {
            this.$refs.list.scrollBy(0, yDiff);
        },
        focusToNextDuration(task) {
            let index = this.tasks.indexOf(task);
            let nextTask = this.tasks[index + 1];
            while (
                index < this.tasks.length &&
                nextTask &&
                (!!nextTask.realEndDate ||
                    nextTask.children?.length > 0 ||
                    (nextTask.hasEEPredecessor && nextTask.hasXSPredecessor))
            ) {
                nextTask = this.tasks[++index];
            }
            if (nextTask) {
                this.$emit('select', nextTask);
                this.$refs['duration_' + nextTask.id].focus();
            }
        },
        focusToNextProgress(task) {
            let index = this.tasks.indexOf(task);
            let nextTask = this.tasks[index + 1];
            while (nextTask && index < this.tasks.length && (!!nextTask.realEndDate || nextTask.children?.length > 0)) {
                nextTask = this.tasks[++index];
            }
            if (nextTask) {
                this.$emit('select', nextTask);
                this.$refs['progress_' + nextTask.id].focus();
            }
        },
        toggleCollapse(item) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
                this.$emit(item.collapsed ? 'expandDeep' : 'collapseDeep', item);
                this.$emit('select', item);
            } else {
                this.timer = setTimeout(() => {
                    this.$nextTick(() => {
                        this.$emit(item.collapsed ? 'expand' : 'collapse', item);
                        this.timer = null;
                    });
                }, 300);
            }
        },
        onResize() {
            this.measureMaxGanttHeight();
            this.measureDayWidth();
            this.measureLineHeight();
        },
        measureMaxGanttHeight() {
            if (this.$refs.headers) {
                this.maxGanttHeight = this.$refs.topRoot.offsetHeight - this.$refs.chart.offsetTop;
            }
        },
        measureDayWidth() {
            if (this.steps.length > 0 && this.$refs.list.clientWidth > this.$refs.tableHeaders.offsetWidth) {
                this.dayWidth = (this.$refs.list.clientWidth - this.$refs.tableHeaders.offsetWidth) / this.numberOfDays;
            } else {
                this.dayWidth = 0;
            }
        },
        measureLineHeight() {
            if (this.options.showRealData && this.options.showPlannedData && this.options.showReferenceData) {
                this.lineHeight = convertEmToPixels(3, this.$el);
            } else {
                this.lineHeight = convertEmToPixels(2, this.$el);
            }
        },
        onRowClick(item) {
            this.$emit('select', item);
        },
        onContextMenu(event, item) {
            this.$emit('contextMenu', { event, item });
        },
        showTooltip(ganttLinePartOvered) {
            if (ganttLinePartOvered.lineType === 'task') {
                if (ganttLinePartOvered.dateRangeType === 'reference') {
                    this.$refs.tooltip.innerHTML = this.$t('project.planning.ganttTooltipReference', {
                        referenceStartDate: humanizeDate(ganttLinePartOvered.referenceStartDate),
                        referenceEndDate: humanizeDate(ganttLinePartOvered.referenceEndDate),
                    });
                } else if (ganttLinePartOvered.dateRangeType === 'planned') {
                    this.$refs.tooltip.innerHTML = this.$t('project.planning.ganttTooltipPlanned', {
                        startDate: humanizeDate(ganttLinePartOvered.startDate),
                        endDate: humanizeDate(ganttLinePartOvered.endDate),
                    });
                } else if (ganttLinePartOvered.dateRangeType === 'progress' && ganttLinePartOvered.progress < 100) {
                    this.$refs.tooltip.innerHTML = this.$t('project.planning.ganttTooltipPendingReal', {
                        realStartDate: humanizeDate(ganttLinePartOvered.realStartDate),
                        estimatedEndDate: humanizeDate(ganttLinePartOvered.estimatedEndDate),
                        progress: ganttLinePartOvered.progress,
                    });
                } else if (ganttLinePartOvered.dateRangeType === 'progress' && ganttLinePartOvered.progress === 100) {
                    this.$refs.tooltip.innerHTML = this.$t('project.planning.ganttTooltipEndedReal', {
                        realStartDate: humanizeDate(ganttLinePartOvered.realStartDate),
                        estimatedEndDate: humanizeDate(ganttLinePartOvered.estimatedEndDate),
                    });
                } else if (ganttLinePartOvered.dateRangeType === 'real') {
                    if (ganttLinePartOvered.realEndDate) {
                        this.$refs.tooltip.innerHTML = this.$t('project.planning.ganttTooltipReal', {
                            realStartDate: humanizeDate(ganttLinePartOvered.realStartDate),
                            realEndDate: humanizeDate(ganttLinePartOvered.realEndDate),
                        });
                    } else if (ganttLinePartOvered.realStartDate) {
                        this.$refs.tooltip.innerHTML = this.$t('project.planning.ganttTooltipPendingReal', {
                            realStartDate: humanizeDate(ganttLinePartOvered.realStartDate),
                            estimatedEndDate: humanizeDate(ganttLinePartOvered.estimatedEndDate),
                            progress: ganttLinePartOvered.progress,
                        });
                    } else {
                        this.$refs.tooltip.innerHTML = this.$t('project.planning.ganttTooltipPlanned', {
                            startDate: humanizeDate(ganttLinePartOvered.startDate),
                            endDate: humanizeDate(ganttLinePartOvered.endDate),
                        });
                    }
                }
            } else if (ganttLinePartOvered.progress) {
                this.$refs.tooltip.innerHTML = this.$t('project.planning.ganttTooltipPendingReal', {
                    realStartDate: humanizeDate(ganttLinePartOvered.startDate),
                    estimatedEndDate: humanizeDate(ganttLinePartOvered.endDate),
                    progress: Math.round(ganttLinePartOvered.progress),
                });
            } else {
                this.$refs.tooltip.innerHTML = this.$t('project.planning.ganttTooltipPlanned', {
                    startDate: humanizeDate(ganttLinePartOvered.startDate),
                    endDate: humanizeDate(ganttLinePartOvered.endDate),
                });
            }
            this.$refs.tooltip.style.display = 'block';
        },
        hideTooltip() {
            this.$refs.tooltip.style.display = 'none';
        },
    },

    mounted: function () {
        this.$nextTick(function () {
            this.measureDayWidth();
            this.measureLineHeight();
            this.measureMaxGanttHeight();
        });
    },
    created() {
        queryProject(this.$route.params.projectId).then((project) => {
            this.disabled = !project.me.allowedFeatures.includes('project_planning');
        });
    },
    watch: {
        tasks: function () {
            this.$nextTick(() => {
                this.measureMaxGanttHeight();
                this.measureLineHeight();
                this.measureDayWidth();
            });
        },
    },
    computed: {
        svgOffset() {
            return getTaskLeft(startOfDay(this.options.refDate), this.minDate) * this.dayWidth;
        },
        numberOfDays: function () {
            return getNumberOfDays(this.options.scale, this.minDate, this.maxDate);
        },
        steps: function () {
            return getSteps(this.options.scale, this.minDate, this.maxDate, this.agenda);
        },
        superSteps: function () {
            return getSuperSteps(this.options.scale, this.minDate, this.maxDate);
        },
        hasData() {
            return this.options.showPlannedData || this.options.showRealData || this.options.showReferenceData;
        },
        tableWidth() {
            let widths = [this.nameColumnWidth];
            if (this.hasData) {
                widths.push(this.prrefColumnWidth);
                widths.push(this.datesColumnWidth);
                widths.push(this.datesColumnWidth);
                if (this.options.showDurationColumn) {
                    widths.push(this.durationColumnWidth);
                }
                if (this.options.showQuantityColumn) {
                    widths.push(this.quantityColumnWidth);
                }
                if (this.options.showProgressColumn) {
                    widths.push(this.progressColumnWidth);
                }
                if (this.options.showLateColumn) {
                    widths.push(this.lateColumnWidth);
                }
            }
            return `calc(${widths.join(' + ')})`;
        },
    },
};
</script>

<style scoped>
.is-weekend {
    background-color: #f0f0f0;
}
.is-current-step {
    border-left-style: solid;
    border-left-width: thin;
}
</style>
