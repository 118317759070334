<template>
    <div class="flex flex-col w-full whitespace-nowrap">
        <span :title="$t('project.follow.planning.referenceStartDate')" v-if="showReferenceData">
            <span class="opacity-0">↦</span>
            <span>
                {{ task.referenceEndDate | humanizeDate }}
            </span>
        </span>
        <span
            :title="
                task.hasXSPredecessor
                    ? $t('project.follow.planning.plannedEndDateWithPredecessor')
                    : $t('project.follow.planning.plannedEndDate')
            "
            v-if="showPlannedData"
        >
            <span :class="{ 'opacity-0': !task.hasEEPredecessor }">↦</span>
            {{ task.plannedEndDate | humanizeDate }}
        </span>
        <span :title="$t('project.follow.planning.realEndDate')" v-if="showRealData">
            <app-date-link
                v-model="task.realEndDate"
                :disabled="task.children?.length > 0 || disabled"
                :label="$t('project.follow.planning.realEndDate')"
                :show-label="false"
                @input="$emit('updateRealEndDate', { id: task.id, realEndDate: $event })"
                v-if="showRealEndDate"
            >
                <span class="opacity-0">↦</span>
                <span :class="{ underline: !disabled && task.children?.length === 0 }">
                    {{ humanizeDate(task.realEndDate || (task.children?.length > 0 ? '' : '...')) }}
                </span>
            </app-date-link>
            <span v-else>&nbsp</span>
        </span>
    </div>
</template>

<script>
import AppDateLink from '@/components/appDateLink/AppDateLink';
import { humanizeDate } from '@/filters/dateFilter';
export default {
    components: { AppDateLink },
    props: ['task', 'showRealData', 'showReferenceData', 'showPlannedData', 'disabled'],
    methods: {
        humanizeDate,
    },
    computed: {
        showRealEndDate() {
            if (this.task.children?.length > 0) {
                return this.task.children.every((task) => task.realEndDate);
            } else return true;
        },
    },
};
</script>
