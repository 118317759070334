import { sortBy } from '@/services/sanitize.service';
import { applyGroupDates, treeToLines } from '@/features/planning/planning2/planning2.service';

export function getChildrenFromParentId(parentNode, tasks) {
    parentNode.children = sortBy(
        tasks.filter((task) => task.parentId === parentNode.id),
        'name',
    ).map((aNode) => ({
        ...aNode,
        bundleId: parentNode.bundleId,
        bundle: parentNode.bundle,
        locationId: parentNode.locationId,
        location: parentNode.location,
    }));
    for (const child of parentNode.children) {
        child.level = parentNode.level + 1;
        child.editable = true;
        getChildrenFromParentId(child, tasks);
    }
}

export function toTaskFree(branches, tasks) {
    const cache = {};
    const result = [];
    for (const branch of branches) {
        const node = branch[0];
        if (!cache[node.id]) {
            result.push(node);
            node.level = 1;
            node.editable = true;
            node.bundleId = branch.bundleId;
            node.bundle = branch.bundle;
            node.locationId = branch.locationId;
            node.location = branch.location;
            getChildrenFromParentId(node, tasks);
            cache[node.id] = node;
        }
    }
    return treeToLines(applyGroupDates(result));
}

export default {
    toTaskFree,
};
