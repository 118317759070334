import { applyGroupDates, treeToLines } from '@/features/planning/planning2/planning2.service';

const defaultLocation = { id: 'default', fullName: 'Ensemble' };

export function toTaskLBSLocationNode(id, name) {
    return {
        id,
        name,
        type: 'location',
        level: 0,
        children: [],
    };
}

export function toTaskLBS(branches, definedLocations) {
    const locations = [defaultLocation, ...definedLocations];
    return treeToLines(
        applyGroupDates(
            locations
                .map((location) => {
                    const locationNode = toTaskLBSLocationNode(location.id, location.fullName);
                    const locationBranches = branches.filter(
                        (task) =>
                            (task.locationId && location.id === task.locationId) ||
                            (!task.locationId && location.id === defaultLocation.id),
                    );
                    appendBranches(locationNode, locationBranches);
                    return locationNode;
                })
                .filter((node) => node.children.length > 0),
        ),
    );
}
export function isLocationNode(location, node) {
    return location.name.includes(node.name);
}
export function appendBranches(locationNode, branches) {
    const cache = {};
    for (const branch of branches) {
        appendBranch(locationNode, branch, locationNode, cache);
    }
}
export function appendBranch(parentNode, branchRest, location, cache) {
    const node = branchRest[0];
    let line = cache[node.id] || cache[location.id + node.id];
    if (!line) {
        if (!isLocationNode(location, node)) {
            const newNode = {
                ...node,
                level: parentNode.level + 1,
                children: [],
            };
            cache[newNode.id] = newNode;
            parentNode.children.push(newNode);
            if (branchRest.length > 1) {
                branchRest.shift();
                appendBranch(newNode, branchRest, location, cache);
            }
        } else {
            if (branchRest.length === 1) {
                parentNode.id = node.id;
                parentNode.plannedStartDate = node.plannedStartDate;
                parentNode.plannedEndDate = node.plannedEndDate;
                parentNode.plannedDuration = node.plannedDuration;
                parentNode.realDuration = node.realDuration;
                parentNode.realEndDate = node.realEndDate;
                parentNode.realStartDate = node.realStartDate;
                parentNode.realProgress = node.realProgress;
            }
            if (branchRest.length > 1) {
                branchRest.shift();
                appendBranch(parentNode, branchRest, location, cache);
            }
        }
    } else if (branchRest.length > 1) {
        branchRest.shift();
        appendBranch(line, branchRest, location, cache);
    }
}

export default {
    toTaskLBS,
};
