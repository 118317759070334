import { getMapById, sortBy } from '@/services/sanitize.service';

export function mapLocationsAndBundles(tasks, bundles, locations) {
    const leaves = getLeaves(tasks);
    const taskMap = getMapById(tasks);
    const branches = leaves.map((task) => getBranch(task, taskMap));
    for (const branch of branches) {
        const location = mapLocation(branch, taskMap, locations);
        if (location) {
            branch.locationId = location.id;
            branch.location = location;
        } else {
            branch.locationId = null;
            branch.location = null;
        }
        const bundle = mapBundle(branch, bundles);
        if (bundle) {
            branch.bundle = bundle;
            branch.bundleId = bundle.id;
        } else {
            branch.bundleId = null;
            branch.bundle = null;
        }

        const leaf = branch[branch.length - 1];
        branch.id = leaf.id;
        branch.plannedStartDate = leaf.plannedStartDate;
        branch.plannedEndDate = leaf.plannedEndDate;
        branch.plannedDuration = leaf.plannedDuration;
        branch.realDuration = leaf.realDuration;
        branch.realEndDate = leaf.realEndDate;
        branch.realStartDate = leaf.realStartDate;
        branch.realProgress = leaf.realProgress;
    }
    return branches;
}
export function isLeaf(task, tasks) {
    return tasks.every((aTask) => aTask.parentId !== task.id);
}
export function getLeaves(tasks) {
    const parentMap = tasks.reduce((acc, task) => {
        if (task.parentId) {
            acc[task.parentId] = true;
        }
        return acc;
    }, {});
    return tasks.filter((task) => !parentMap[task.id]);
}
const LOCATION_ORDER = ['folder', 'location', 'zone', 'room'];
export function mapLocation(branch, locationMap, locations) {
    // explicit links case
    const location = branch
        .filter((task) => task.locationId)
        .map((task) => task.locationId)
        .reduce((acc, link) => {
            const location1 = locationMap[acc];
            const location2 = locationMap[link];
            if (location1 && location2) {
                if (LOCATION_ORDER.indexOf(location1.type) > LOCATION_ORDER.indexOf(location1.type)) {
                    return location1;
                } else {
                    return location2;
                }
            } else {
                return location2 || location1;
            }
        }, null);
    if (location) {
        return location;
    }
    // implicit links case
    else {
        const links = branch.reduce((acc, task) => {
            let locationIds = locations.filter((aLocation) => aLocation.fullName === task.name) || [];
            if (locationIds.length === 0) {
                locationIds = locations.filter((aLocation) => aLocation.fullName.includes(task.name)) || [];
            }
            return [...acc, ...locationIds];
        }, []);
        return sortBy(
            links.map((link) => {
                link.score = links.filter((aLink) => link.fullName.startsWith(aLink.fullName)).length;
                return link;
            }),
            'score',
        ).pop();
    }
}

export function getBranch(task, taskMap) {
    const parent = task.parentId && taskMap[task.parentId] ? taskMap[task.parentId] : null;
    if (parent) {
        return [...getBranch(parent, taskMap), task];
    } else {
        return [task];
    }
}
export function mapBundle(branch, bundles) {
    // explicit links case
    const bundleId = branch
        .filter((task) => task.bundleId)
        .map((task) => task.bundleId)
        .pop();
    if (bundleId) {
        return bundles.find((aBundle) => bundleId === aBundle.id);
    }
    // implicit links case
    else {
        return branch
            .map((task) => {
                return bundles.find((aBundle) => task.name.startsWith(aBundle.reference + ' '));
            })
            .filter((a) => !!a)
            .map((bundle) => bundle)
            .pop();
    }
}

export default {
    getLeaves,
};
